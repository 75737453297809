import React from 'react'
import styles from './service.module.css'

const Service = ({ iconClass, title, description }) => {
  return (
    <div className={ `${styles.service} box mb-40` }>
      <i className={ iconClass }></i>
      <h4>{ title }</h4>
      <p>{ description }</p>
    </div>
  )
}

export default Service
