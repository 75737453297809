import React from 'react'
import PropTypes from 'prop-types'
import Social from './social'
import styles from './home-section.module.css'
import background from '../../images/home-bg.jpg'

const HomeSection = ({ name }) => {
  return (
    <div id="home-section" className={styles.home} style={{ backgroundImage: `url(${background})` }}>
      <section>
        <div className={styles.banner}>
          <h1>Hello, I'm { name }</h1>
          <p className={styles.headline}>
            I'm a Full Stack Developer.
          </p>
        </div>
        <Social />
      </section>
    </div>
  )
}

HomeSection.propTypes = {
  name: PropTypes.string,
}

HomeSection.defaultProps = {
  name: ``
}

export default HomeSection
