import React from 'react'
import styles from './interest-item.module.css'

const InterestItem = ({ iconClass, title, short }) => {
  return (
    <div className={ `${styles.interest} ${short}` }>
      <i className={ iconClass }></i>
      <div className={ styles.description }>
        <h6>{ title }</h6>
      </div>
    </div>
  )
}

export default InterestItem
