import React, { useEffect } from 'react'
import preloaderStyles from './preloader.module.css'

const Preloader = () => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#preloader').classList.add('loaded');
    }, 300);
  }, []);

  return (
    <div id="preloader" className={preloaderStyles.preloader}>
      <div className={preloaderStyles.loadingArea}>
        <div className={preloaderStyles.circle}></div>
      </div>
      <div className={preloaderStyles.leftSide}></div>
      <div className={preloaderStyles.rightSide}></div>
    </div>
  )
}

export default Preloader
