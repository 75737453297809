import React from 'react'
import styles from './resume-section.module.css'

const ExperienceItem = ({ position, info, date, organisation, description}) => {
  const infoText = info && <div><small>{info}</small></div>
  let details = null

  if (Array.isArray(description)) {
    details = []

    description.forEach((item, index) => {
      details.push(<li key={`experience-item-${index}`}>{ item }</li>)
    })

    details = <ul>{ details }</ul>
  } else {
    details = <p>{description}</p>
  }

  return (
    <div className={ styles.item }>
      <div className={ styles.inner }>
        <h4>
          { position }
          {infoText}
        </h4>
        <p className="mb-15">
          <i className="far fa-calendar-alt"></i>
          { date } | { organisation }
        </p>
      </div>
      {details}
    </div>
  )
}

export default ExperienceItem
