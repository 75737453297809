import React from 'react'
import InterestItem from '../interest-item/interest-item'

const Interests = () => {
  return (
    <div className="row mt-100">
      <div className="col-12">
        <div className="header-box mb-50">
          <h3>My Interests</h3>
        </div>
      </div>
      <div className="col-12">
        <div className="box">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-robot" title="Technology" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-music" title="Music" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-route" title="Travelling" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="far fa-image" title="Photos" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-film" title="Movies" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-basketball-ball" title="Basketball" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-mountain" title="Mountains" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <InterestItem iconClass="fas fa-hiking" title="Hiking" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Interests
