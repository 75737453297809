import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = ({ title }) => (
  <div className="section-title mt-70 mob-mt">
    <h2>{ title }</h2>
    <span></span>
  </div>
)

SectionTitle.propTypes = {
  title: PropTypes.string,
}

SectionTitle.defaultProps = {
  title: ``
}

export default SectionTitle
