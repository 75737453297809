import React from 'react'
import styles from './home-section.module.css'

const Social = () => {
  return (
    <div className={styles.social}>
      <ul>
        <li><a  href="https://www.facebook.com/aaron.robertshaw" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
        <li><a  href="https://www.instagram.com/azrobertshaw/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
        <li><a  href="https://www.linkedin.com/in/aaron-robertshaw/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
        <li><a  href="https://github.com/aaronrobertshaw" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a></li>
      </ul>
    </div>
  )
}

export default Social
