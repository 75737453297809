import React from 'react'
import SectionTitle from '../section-title/section-title'
import ContactForm from './contact-form'
import ContactInfo from './contact-info'
import styles from './contact-section.module.css'

const ContactSection = () => {
  return (
    <div className={ `page ${ styles.contact }` }>
      <section className="container">
        <SectionTitle title="Contact Me" />
        <ContactForm />
        <ContactInfo />
      </section>
    </div>
  )
}

export default ContactSection
