import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Preloader from '../components/preloader/preloader'
import HeaderMobile from '../components/header-mobile/header-mobile'
import Navigation from '../components/navigation/navigation'
import HomeSection from '../components/home-section/home-section'
import AboutSection from '../components/about-section/about-section'
import ResumeSection from '../components/resume-section/resume-section'
import ContactSection from '../components/contact-section/contact-section'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          name,
          location
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title=" " />
      <Preloader />
      <HeaderMobile name={data.site.siteMetadata.name} />
      <div id="home">
        <div id="about">
          <div id="resume">
            <div id="portfolio">
              <div id="contact">
                <Navigation />
                <HomeSection name={data.site.siteMetadata.name} />
                <AboutSection name={data.site.siteMetadata.name} location={data.site.siteMetadata.location} />
                <ResumeSection />
                <ContactSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
