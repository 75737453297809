/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../styles/plugins/bootstrap-grid.css"
import "../styles/plugins/font-awesome.css"
import "../styles/plugins/font-mfizz.css"
import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
import "../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css"
import "./layout.css"
import "../styles/buttons.css"


const Layout = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
