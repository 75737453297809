import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './header-mobile.module.css'

const HeaderMobile = ({ name }) => {
  useEffect(() => {
    const mainNav = document.querySelector('#header-main')

    document.querySelector('.' + styles.headerToggle).addEventListener('click', (e) => {
      e.preventDefault()
      if (mainNav) {
        mainNav.classList.toggle('on')
      }
    })
  }, [])

  return (
    <div className={styles.headerMobile}>
      <a href="/" className={styles.headerToggle}><i className="fas fa-bars"></i></a>
      <h2>{ name }</h2>
    </div>
  )
}

HeaderMobile.propTypes = {
  name: PropTypes.string,
}

HeaderMobile.defaultProps = {
  name: ``
}

export default HeaderMobile
