import React from 'react'
import SectionTitle from '../section-title/section-title'
import ExperienceItem from './experience-item'
import InterestItem from '../interest-item/interest-item'
import styles from './resume-section.module.css'
import pdf from '../../files/AaronRobertshaw-Resume-OnePage.pdf'

const ResumeSection = () => {

  const freelance = [
    "Rebuilt Ruby on Rails app on the cloud (Heroku & AWS).",
    "Provided real-time integration with Xero accounting system.",
    "Crafted governmental safety compliance app.",
  ]

  const jcs = [
    "Built web apps for tourism companies",
    "Developed modular CMS using PHP & Yii2",
    "Introduced Git, Bitbucket, Jira to company",
  ]

  const liquidus = [
    "Assisted dozens of businesses in moving online.",
    "Built app that reduced admin time for one company by 80%",
    "Implemented online booking & newsletter system for medical clinic",
  ]

  const education = [
    "Majors: Software Engineering & Computer Science",
    "Awards: Golden Key Honour Society",
  ]

  return (
    <div className={ `page ${styles.resume}` }>
      <section className="container">
        <SectionTitle title="Resume" />
        <div className="row mt-100">
          <div className="col-12 col-lg-6 mb-100">
            <div className="header-box mb-50">
              <h3>Experience</h3>
            </div>
            <div className={`${styles.experience} box ${ styles.work }` }>
              <ExperienceItem position="Full Stack Developer" date="2018-Present" organisation="Freelance" description={freelance} />
              <ExperienceItem position="Senior Web Developer" date="2013-2018" organisation="JCS Dynamix" description={ jcs } />
              <ExperienceItem position="Full Stack Developer" date="2008-2013" organisation="Liquidus" description={ liquidus } />
              <div className="col-12" style={{ marginLeft: '40px' }}>
                <a href={pdf} className="btn" target="_blank" rel="noopener noreferrer">Download Resume</a>
                <p style={{ marginTop: '30px', fontSize: '12px' }}>
                  Note: If you would like a detailed version of my resume complete with references simply <a href="#contact" className="underline-link">get in touch</a> and I'll send one through.
                </p>
              </div>
            </div>
            <div className="header-box mb-50">
              <h3>Education</h3>
            </div>
            <div className={`${styles.experience} box`}>
              <ExperienceItem position="Bachelor of Information Technology" info=" with Advanced Studies" date="2003" organisation="Griffith University" description={ education } />
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-100">
            <div className="header-box mb-50">
              <h3>Skills &amp; Technologies</h3>
            </div>
            <div className="box">
              <div className="row">
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-js" title="Javascript" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-react" title="React" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-node" title="Node.js" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-ruby" title="Ruby" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-ruby-on-rails" title="Ruby on Rails" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-php-alt" title="PHP" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-html5" title="HTML" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-css3" title="CSS" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-sass" title="Sass" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-grunt" title="Grunt" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-git" title="Git" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-postgres" title="Postgres" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-mysql-alt" title="MySQL" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="icon-docker" title="Docker" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-linux" title="Linux" />
                </div>
                <div className="col-12">
                  <InterestItem short="short" iconClass="fab fa-aws" title="Amazon Web Services" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResumeSection
