import React from 'react'
import styles from './info-icon.module.css'

const InfoIcon = ({ iconClass, title, tagline }) => {
  return (
    <div className={ styles.infoIcon }>
      <i className={ iconClass }></i>
      <div className={ styles.descIcon }>
        <h6>{ title }</h6>
        <p>{ tagline }</p>
      </div>
    </div>
  )
}

export default InfoIcon
