import React, { useEffect } from 'react'
import styles from './contact-section.module.css'

const ContactForm = () => {

  useEffect(() => {
    const form = document.getElementById('contact-form')
    const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const displayError = (id, msg) => {
      const el     = document.getElementById(id)
      el.innerHTML = msg
      el.style     = ''
    }

    const clearError = (id) => {
      const el     = document.getElementById(id)
      el.innerHTML = ""
      el.style     = 'display: none;'
    }

    const formIsValid = () => {
      let errors = false

      if (!form.name.value || form.name.value.length < 2) {
        errors = true
        displayError('name-error', 'Please enter your name')
      } else {
        clearError('name-error')
      }

      if (!form.email.value || !expression.test(String(form.email.value).toLowerCase())) {
        errors = true
        displayError('email-error', 'Please enter a valid email')
      } else {
        clearError('email-error')
      }

      if (!form.note.value || form.note.value.length < 2) {
        errors = true
        displayError('note-error', 'Please enter a message')
      } else {
        clearError('note-error')
      }

      return !errors
    }

    const getFormData = () => {
      const serialized = [];
      for (let i = 0; i < form.elements.length; i++) {
        let field = form.elements[i]

        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
          continue
        }

        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value))
      }

      return serialized.join('&')
    };

    const showResult = (el) => {
      el.classList.add('show')
      setTimeout(() => el.classList.remove('show'), 3000);
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault()

      const btn     = document.getElementById('submit-button')
      const loader  = document.getElementById('loader')
      const success = document.getElementById('success')
      const error   = document.getElementById('error')

      if (formIsValid()) {
        btn.style    = 'display: none;'
        loader.style = 'display: block;'

        fetch(form.getAttribute('action'), {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: getFormData()
        })
        .then(() => {
          btn.style = 'display: inline-block;'
          loader.style = 'display: none;'
          form.reset()
          showResult(success)
        })
        .catch(() => {
          btn.style = 'display: inline-block;'
          loader.style = 'display: none;'
          showResult(error)
        })
      }

      return false
    })
  }, [])

  return (
    <div className={ `${styles.contactForm} mt-100` }>
      <form id="contact-form" action="/" autoComplete="false" className={`box ${styles.contactValid}`} data-netlify="true" name="contact" netlify-honeypot="forbotsfield">
        <input type="hidden" name="form-name" value="contact" />
        <div style={{ display: 'none' }}>
          <input name="forbotsfield" />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <input type="text" autoComplete="false" name="name" id="name" className="form-control" placeholder="Name *" />
            <label htmlFor="name" id="name-error" className={ styles.error } style={{ display: 'none' }}></label>
          </div>
          <div className="col-12 col-lg-6">
            <input type="text" autoComplete="false" name="email" id="email" className="form-control" placeholder="Email *" />
            <label htmlFor="email" id="email-error" className={ styles.error } style={{ display: 'none' }}></label>
          </div>
          <div className="col-12 mt-50 mb-50">
            <textarea autoComplete="false" name="note" id="note" className="form-control" placeholder="Your Message"></textarea>
            <label htmlFor="note" id="note-error" className={ styles.error } style={{ display: 'none' }}></label>
          </div>
          <div className="col-12 text-center">
            <button className="btn" id="submit-button" name="submit-button" type="submit">Send Message</button>
            <div id="loader">
              <i className="fas fa-sync"></i>
            </div>
          </div>
          <div className="col-12">
            <div className={ styles.errorMessages }>
              <div id="success">
                <i className="far fa-check-circle"></i> Thank you, your message has been sent. I'll be in touch shortly.
              </div>
              <div id="error">
                <i className="far fa-times-circle"></i>Error occurred while sending email. Please try again later.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
