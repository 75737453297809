import React from 'react'
import PropTypes from 'prop-types'
import UserImage from './user-image'
import InfoIcon from '../info-icon/info-icon'
import styles from './about-section.module.css'
import pdf from '../../files/AaronRobertshaw-Resume-OnePage.pdf'

const PersonalInfo = ({ name, location }) => {
  const yearsExperience = Math.round((new Date()).getFullYear() - 2005);
  return (
    <div className="row mt-100">
      <div className="col-12">
        <div className={`${styles.info} box`}>
          <div className="row">
            <div className="col-sm-4 col-lg-3">
              <div className={styles.photo}>
                <UserImage name={name} />
              </div>
            </div>
            <div className={ `${styles.aboutMe} col-sm-8 col-lg-9` }>
              <h4>{name}</h4>
              <div className={ styles.location }>
                <i className="fas fa-map-marked-alt"></i> {location}
              </div>
              <p style={{ marginBottom: '15px' }}>
                I possess a passion for problem solving and seeing solutions come to life on the web.
                When I'm not coding, reading or learning something new, I'm likely out in the hills hiking,
                watching basketball or on the beach with my feet in the sand.
              </p>
              <p>
                Recently, I have been bitten by the adventure travel bug. If you have a project that
                will take me off the beaten path, literally or even just metaphorically, then <a className={ styles.talk } href="#contact">we should talk!</a>
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <InfoIcon iconClass="fas fa-award" title={`${yearsExperience} Years Industry`} tagline="Experience" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <InfoIcon iconClass="fas fa-certificate" title="200+ Successful" tagline="Projects" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <InfoIcon iconClass="fas fa-user-astronaut" title="Freelance" tagline="Available" />
            </div>
            <div className={ `${styles.btnContainer} col-sm-6 col-md-12 col-lg-3` }>
              <a href={pdf} className="btn" target="_blank" rel="noopener noreferrer">Download Resume</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PersonalInfo.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
}

PersonalInfo.defaultProps = {
  name: ``,
  location: ``,
}

export default PersonalInfo
