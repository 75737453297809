import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const UserImage = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      userImage: file(relativePath: { eq: "user-photo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.userImage.childImageSharp.fluid} title={name} alt={name} sizes={{ ...data.userImage.childImageSharp.fluid, aspectRatio: 1 }} />
}

export default UserImage
