import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../section-title/section-title'
import PersonalInfo from './personal-info'
import Interests from './interests'
import Services from './services'
import styles from './about-section.module.css'

const AboutSection = ({ name, location }) => {
  return (
    <div id="about-section" className={`page ${ styles.about }`}>
      <section className="container">
        <SectionTitle title="About Me" />
        <PersonalInfo name={name} location={location} />
        <Interests />
        <Services />
        {/* <Testimonials /> */}
      </section>
    </div>
  )
}

export const pageQuery = graphql`
  query HeaderImageQuery {
    headerImage: imageSharp(id: { regex: "/header/" }) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
  }
`

AboutSection.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
}

AboutSection.defaultProps = {
  name: ``,
  location: ``,
}

export default AboutSection
