import React from 'react'
import Service from '../service/service'

const Services = () => {
  const design = "Are you looking for a new fresh look for your website or in need of a truly user friendly interface for your app? I place the focus directly on your customer's experience and how that helps drive your business."
  const development = "Whether you need a design to come to life or someone to rescue a neglected site, I can help. My skills in the latest web technologies allow me to create a flexible, responsive site that you'll love no matter which device you are viewing it on."
  const cloud = "Is your business expanding? Are you interested in seeing how 'the cloud' can work for you? Let me guide you through leveraging some of the awesome platforms out there. Stand on the shoulders of giants like Amazon and Google."
  const support = "A project being delivered is only the beginning. I've years of experience training staff in new technology and supporting systems within business. Whenever an issue arises, you'll have the peace of mind knowing support is never far away."

  return (
    <div className="row mt-100">
      <div className="col-12">
        <div className="header-box mb-50">
          <h3>Services</h3>
        </div>
      </div>
      <div className="col-sm-6">
        <Service iconClass="fas fa-desktop" title="Web Design" description={design} />
      </div>
      <div className="col-sm-6">
        <Service iconClass="fas fa-cogs" title="Web Development" description={development} />
      </div>
      <div className="col-sm-6">
        <Service iconClass="fas fa-cloud" title="Cloud Development" description={cloud} />
      </div>
      <div className="col-sm-6">
        <Service iconClass="fas fa-medkit" title="Great Support" description={support} />
      </div>
    </div>
  )
}

export default Services
