import React from 'react'
import styles from './contact-section.module.css'

const ContactInfo = () => {
  return (
    <div className={ `box ${styles.contactInfo } mb-60` }>
      <div className="row">
        <div className={ `col-12 col-lg-6 ${ styles.info }` }>
          <i className="fas fa-paper-plane"></i>
          <p>aaron<span className={ styles.protect }>ISNOTHERE</span>@clickweave.com</p>
          <span>Email</span>
        </div>
        <div className={`col-12 col-lg-6 ${styles.info}`}>
          <i className="fas fa-map-marker-alt"></i>
          <p>Woodgate QLD, Australia</p>
          <span>Locaion</span>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
