import React, { useEffect } from 'react'
import Logo from '../logo/logo'
import styles from './navigation.module.css'

const Navigation = () => {
  useEffect(() => {
    const mainNav = document.querySelector('#header-main')

    mainNav.addEventListener('click', (e) => {
      mainNav.classList.remove('on')
    })
  }, [])

  return (
    <div id="header-main" className={styles.headerMain}>
      <Logo />
      <ul>
        <li data-tooltip="home" data-position="top">
          <a href="#home" className="fas fa-house-damage">&nbsp;</a>
        </li>
        <li data-tooltip="about" data-position="top">
          <a href="#about" className="icon-a fas fa-user-tie">&nbsp;</a>
        </li>
        <li data-tooltip="resume" data-position="top">
          <a href="#resume" className="icon-r fas fa-address-book">&nbsp;</a>
        </li>
        <li data-tooltip="contact" data-position="top">
          <a href="#contact" className="icon-c fas fa-envelope">&nbsp;</a>
        </li>
      </ul>
    </div>
  )
}

export default Navigation
